<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Requests from "./requests.vue";
import { requests } from "./entries";
import RequestTypes from "./request-types.vue";

/**
 * Requests component
 */
export default {
  page: {
    title: "Requests",
  },
  components: {
    Layout,
    PageHeader,
    Requests,
    RequestTypes,
  },
  data() {
    return {
      requests,
      title: "Requests",
      items: [
        {
          text: "HR",
          href: "/",
        },
        {
          text: "Requests",
          active: true,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth?.currentUser;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card no-body>
      <b-tabs content-class="mt-3" card fill pills>
        <b-tab title="All Requests" active>
          <Requests :requests="requests" />
        </b-tab>
        <b-tab
          active
          v-if="currentUser?.user?.name.toLowerCase() === 'company'"
          title="Request Types"
        >
          <RequestTypes />
        </b-tab>
      </b-tabs>
    </b-card>
  </Layout>
</template>