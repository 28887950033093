<script>
import { mapGetters } from "vuex";
import {
  attendanceTypeMethods,
  leaveTypeMethods,
} from "../../../state/helpers";

/**
 * Request Types component
 */
export default {
  props: {
    showPagination: {
      default: true,
      type: Boolean,
    },
    showFilter: {
      default: true,
      type: Boolean,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  page: {},
  components: {},
  data() {
    return {
      activeType: "leave",
      requests: {
        leave: [],
        attendance: [],
      },
      totalRows: 1,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "start_date",
      sortDesc: true,
      attendance_request: {},
      leave_request: {},
      fields: [
        { key: "is_active", label: "Status", sortable: true },
        { key: "name", sortable: true },
        { key: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters("attendanceTypes", ["attendance_types"]),
    ...mapGetters("leaveTypes", ["leave_types"]),
    /**
     * Total no. of records
     */
    rows() {
      switch (this.activeType) {
        case "attendance":
          return this.attendance_types.length;
        case "leave":
          return this.leave_types.length;
      }
      return 0;
    },
  },
  mounted() {
    switch (this.activeType) {
      case "leave":
        this.totalRows = this.leave_types.length;
        break;
      case "attendance":
        this.totalRows = this.attendance_types.length;
        break;
    }
  },
  methods: {
    ...attendanceTypeMethods,
    ...leaveTypeMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(row) {
      console.log(row);
    },
    toggleModal(item) {
      console.log(item);
      if (this.activeType === "leave") {
        this.leave_request = { ...item };
      } else {
        this.attendance_request = { ...item };
      }
      this.$bvModal.show(`${this.activeType}-modal`);
    },
    async updateRecord() {
      switch (this.activeType) {
        case "leave":
          console.log(this.leave_request);
          await this.createLeaveType(this.leave_request);
          break;
        case "attendance":
          console.log(this.attendance_request);
          await this.createAttendanceType(this.attendance_request);
          break;
      }
      this.$bvModal.hide(`${this.activeType}-modal`);
    },
  },
  created() {
    this.getAttendanceTypes();
    this.getLeaveTypes();
  },
};
</script>

<template>
  <div>
    <div class="row align-items-center mb-3">
      <div v-if="showPagination" class="col-sm-12 col-md-4">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 text-center">
        <b-button-group size="sm">
          <b-button
            :variant="activeType === 'leave' ? 'primary' : 'outline-primary'"
            @click="activeType = 'leave'"
          >
            Leave
          </b-button>
          <b-button
            :variant="
              activeType === 'attendance' ? 'primary' : 'outline-primary'
            "
            @click="activeType = 'attendance'"
          >
            Attendance
          </b-button>
        </b-button-group>
      </div>
      <!-- Search -->
      <div v-if="showFilter" class="col-sm-12 col-md-4">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <b-button
            class="mx-2"
            size="sm"
            variant="success"
            @click="toggleModal({})"
          >
            <i class="mdi mdi-plus"></i
          ></b-button>
        </div>
      </div>
      <!-- End search -->
    </div>
    <b-table
      hover
      striped
      :fields="fields"
      :items="
        activeType === 'attendance' ? this.attendance_types : this.leave_types
      "
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(name)="row">
        <b-button variant="link" v-b-modal="`${activeType}-modal`">{{
          row.value
        }}</b-button>
      </template>
      <template #cell(is_active)="row">
        <b-badge :variant="row.value ? 'success' : 'danger'">
          <i :class="`mdi mdi-${row.value ? 'check' : 'close'}`"></i>
        </b-badge>
      </template>
      <template #cell(action)="row">
        <b-button size="sm" variant="primary" @click="toggleModal(row.item)">
          <i class="mdi mdi-pencil"></i>
        </b-button>
      </template>
      <template #cell()="row">
        <div class="text-nowrap text-capitalize">
          {{ row.value || "---" }}
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <div
          v-if="showPagination"
          class="dataTables_paginate paging_simple_numbers float-right"
        >
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="attendance-modal"
      size="lg"
      centered
      title="Attendance Request Type"
      title-class="font-18 text-capitalize"
      hide-footer
    >
      <form class="form" role="form">
        <b-row>
          <b-col md="6">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                type="text"
                name="name"
                v-model="attendance_request.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Unlock after(months)" label-for="unlock_after">
              <b-form-input
                type="number"
                name="unlock_after"
                v-model="attendance_request.unlock_after_months"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="attendance_request.require_clock"
                switch
                class="mb-1"
              >
                <label>Require clock-in/out</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="attendance_request.require_clock">
            <b-form-group>
              <b-form-checkbox
                v-model="attendance_request.remotely"
                switch
                class="mb-1"
              >
                <label>Remotely</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="attendance_request.unlimited"
                switch
                class="mb-1"
              >
                <label>Unlimited</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="attendance_request.multi_level_approval"
                switch
                class="mb-1"
              >
                <label>Multi-level approval</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="attendance_request.limit_hours"
                switch
                class="mb-1"
              >
                <label>Hours</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="!attendance_request.unlimited"
              :label="`Limited to (${
                attendance_request.limit_hours ? 'hours' : 'days'
              })`"
              label-for="limit"
            >
              <b-form-input
                type="number"
                name="limit"
                v-model="attendance_request.limit"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="!attendance_request.unlimited"
              label="Renew"
              label-for="parent"
            >
              <select
                class="form-control"
                v-model="attendance_request.renew_after_days"
              >
                <option value="1">Weekly</option>
                <option value="4">Monthly</option>
                <option value="52">Yearly</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Religion" label-for="parent">
              <select
                class="form-control"
                v-model="attendance_request.religion"
              >
                <option :value="null">None</option>
                <option value="christian">Christianity</option>
                <option value="muslim">Islam</option>
                <option value="jewish">Judaism</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Gender" label-for="parent">
              <select class="form-control" v-model="attendance_request.gender">
                <option :value="null">None</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea name="note" v-model="attendance_request.note" />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button variant="primary" @click="updateRecord">Submit</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="leave-modal"
      size="lg"
      centered
      title="Leave Request Type"
      title-class="font-18 text-capitalize"
      hide-footer
    >
      <form class="form" role="form">
        <b-row>
          <b-col md="12">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                type="text"
                name="name"
                v-model="leave_request.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Unlock after(months)" label-for="unlock_after">
              <b-form-input
                type="number"
                name="unlock_after"
                v-model="leave_request.unlock_after_months"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Required days before submitting"
              label-for="days_before"
            >
              <b-form-input
                type="number"
                name="days_before"
                v-model="leave_request.required_days"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox v-model="leave_request.paid" switch class="mb-1">
                <label>Paid?</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="leave_request.unlimited"
                switch
                class="mb-1"
              >
                <label>Unlimited</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="leave_request.attachments_required"
                switch
                class="mb-1"
              >
                <label>Require attachment</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="leave_request.multi_level_approval"
                switch
                class="mb-1"
              >
                <label>Multi-level approval</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="!leave_request.unlimited"
              label="Balance (days)"
              label-for="balance"
            >
              <b-form-input
                type="number"
                name="balance"
                v-model="leave_request.balance"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Max Consecutive Days (0 = unlimited)">
              <b-form-input
                min="0"
                type="number"
                name="balance"
                v-model="leave_request.max_consecutive_days"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="!leave_request.unlimited"
              label="Renew"
              label-for="parent"
            >
              <select
                v-model="leave_request.renew_after_months"
                class="form-control"
              >
                <option value="1">Monthly</option>
                <option value="12">Yearly</option>
                <option value="13">Other</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            v-if="
              parseInt(leave_request.renew_after_months) > 12 &&
              !leave_request.unlimited
            "
          >
            <b-form-group label="Renew after(months)" label-for="renew_after">
              <b-form-input
                type="number"
                name="renew_after"
                v-model="leave_request.renew_after_months"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="!leave_request.unlimited">
            <b-form-group label="How is balance earned?" label-for="parent">
              <select
                class="form-control"
                v-model="leave_request.balance_calculation"
              >
                <option value="1">All at once</option>
                <option value="12">Each Month</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Religion" label-for="parent">
              <select class="form-control" v-model="leave_request.religion">
                <option :value="null">None</option>
                <option value="christian">Christianity</option>
                <option value="muslim">Islam</option>
                <option value="jewish">Judaism</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Gender" label-for="parent">
              <select class="form-control" v-model="leave_request.gender">
                <option :value="null">None</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="leave_request.attachments_required">
            <b-form-group label="Attachments">
              <b-form-input
                type="number"
                min="1"
                v-model="leave_request.attachments"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea name="note" v-model="leave_request.note" />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button variant="primary" @click="updateRecord">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>